@charset "utf-8";
/* Global Styles */

body,
html {
  margin-left: auto;
  margin-right: auto;
  background-color: #000000;
  font-family: "Poppins", sans-serif;
}

code {
  font-family: "Poppins", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Main Content */
#mainContent {
  padding-top: 100px;
  padding-bottom: 57px;
  width: 80%;
  margin: 0 auto;
}