/* Logo placeholder*/
#profileLogo {
    float: left;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.aboutTag {
    color: white;
    font-weight: 400;
    color: #7ED957;
}

#mainContent .profileLogo {
    width: 200px;
    text-align: center;
}

#mainContent .profilePhoto {
    margin-top: 3%;
}

#mainContent .profilePhoto img {
    width: 300px;
    height: 300px;
    border: 5px rgba(208, 207, 207, 1.00) solid;
    border-radius: 80%;
    clear: both;
}

#mainContent .profileHeader h1 {
    color: white;
    font-size: 30px;
    font-weight: 700;
    line-height: 24px;
}

#mainContent .profileHeader h3 {
    color: rgba(146, 146, 146, 1.00);
    font-size: 17px;
    font-weight: 500;
    font-style: italic;
    line-height: 24px;
}

hr {
    background-color: rgba(208, 207, 207, 1.00);
    height: 1px;
}

#mainContent .profileHeader p {
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.8;
    overflow-y: auto;
}

#mainContent .socialNetworkNavBar {
    text-align: end;
    display: block;
    margin-top: 60px;
    clear: both;
}

#mainContent .socialNetworkNavBar .socialNetworkNav img {
    width: 50px;
    height: 50px;
    border-radius: 20%;
    cursor: pointer;
}

#mainContent .socialNetworkNavBar .socialNetworkNav img:hover {
    opacity: 0.5;
}


/* Media query for Mobile devices*/
@media only screen and (min-width : 285px) and (max-width : 480px) {

    /* Whole page content */
    #mainWrapper {
        width: 80%;
        padding-left: 10%;
    }

    /* Main Content */
    #mainContent {
        width: 100%;
        float: none;
        text-align: center;
    }

    #mainContent .profileLogo {
        display: inline-block;
        width: 50px;
        height: 50px;
    }

    #mainContent .profileLogo img {
        width: 50px;
        height: 50px;
    }

    #mainContent .profilePhoto {
        display: inline-block;
    }

    #mainContent .profilePhoto img {
        width: 150px;
        height: 150px;
    }

    #mainContent .profileHeader {
        text-align: center;
        margin-top: 10%;
    }

    #mainContent .profileHeader p {
        text-align: justify;
    }

    #mainContent .socialNetworkNavBar {
        text-align: center;
        display: block;
        margin-top: 60px;
        clear: both;
        margin-bottom: 15%;
    }

    #mainContent .socialNetworkNavBar .socialNetworkNav {
        width: 60px;
        height: 60px;
        display: inline-block;
        margin-right: 23px;
    }
}

/* Media Query for Tablets */
@media only screen and (min-width : 481px) and (max-width : 1024px) {

    /* Main Content */
    #mainContent {
        width: 100%;
        float: none;
    }

    #mainContent .profileLogo {
        display: inline-block;
    }

    #mainContent .profilePhoto {
        float: left;
        clear: both;
        margin-right: 3%;
        margin-top: 5%;
    }

    #mainContent .profilePhoto img {
        width: 250px;
        height: 250px;
    }

    #mainContent .profileHeader {
        text-align: left;
        margin-top: 7%;
    }

    #mainContent .socialNetworkNavBar {
        text-align: end;
        margin-left: -4%;
    }

    #mainContent .socialNetworkNavBar .socialNetworkNav {
        width: 74px;
        height: 74px;
        display: inline-block;
        margin-right: 23px;
    }
}

/* Desktops and laptops  */
@media only screen and (min-width:1025px) {
    .profileLogo {
        float: left;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 139px;
        text-align: center;
        color: rgba(255, 255, 255, 1.00);
        background-color: rgba(190, 190, 190, 1.00);
    }

    #mainContent .profilePhoto {
        float: left;
        clear: both;
        margin-right: 3%;
    }

    #mainContent .profileHeader {
        text-align: left;
        /* padding-top: 10%; */
    }

    #mainContent .socialNetworkNavBar {
        text-align: end;
    }

    #mainContent .socialNetworkNavBar .socialNetworkNav {
        width: 50px;
        height: 50px;
        display: inline-block;
        margin-right: 23px;
    }
}