.todo-container {
    width: 400px;
    margin: 0 auto;
}

.todo-container h1 {
    text-align: center;
    margin-bottom: 20px;
}

.todo-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    font-size: 16px;
    flex-grow: 1;

}

.todo-input-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.todo-list {
    list-style-type: none;
    padding: 0;
}

.todo-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 5px;
    background-color: #f8f8f8;
    border-radius: 5px;
}

.todo-item input[type="text"] {
    width: 60%;
    margin-right: 10px;
    padding: 5px;
}

.todo-item button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.todo-item button.edit-btn {
    background-color: #7ED957;;
    color: white;
    margin-left: 5px; 
}

.todo-item button.delete-btn {
    background-color: #f44336;
    color: white;
    margin-left: 5px; 
}

.save-edit-todo {
    background-color: #7ED957;
    color: white;
}

.todo-btn-container {
    position: absolute;
    right: 0;
    margin-right: 5px;
}

.add-todo-btn {
    padding: 10px 20px;
    background-color: #7ED957;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 10px;
    flex-shrink: 0;
}

.add-todo-btn:hover {
    background-color: #0056b3;
}