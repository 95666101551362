#container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}

.board {
    display: grid;
    grid-template-columns: repeat(3, 100px);
    grid-template-rows: repeat(3, 100px);
    gap: 5px;
}

.square {
    width: 100px;
    height: 100px;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7ED957;
}

.square:hover {
    background-color:#7ED957;;
    ;
}

.status {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #7ED957;
}

.resetButton {
    margin-top: 15px;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    background-color: #7ED957;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.board-row {
    display: flex;
}

@media only screen and (min-width : 285px) and (max-width : 480px) {
    .board {
        grid-template-columns: repeat(3, 80px);
        grid-template-rows: repeat(3, 80px);
    }

    .square {
        width: 80px;
        height: 80px;
        font-size: 24px;
    }
}

@media only screen and (min-width : 481px) and (max-width : 1024px) {
    .board {
        grid-template-columns: repeat(3, 100px);
        grid-template-rows: repeat(3, 100px);
    }

    .square {
        width: 100px;
        height: 100px;
        font-size: 24px;
    }

    .status {
        font-size: 24px;
    }

    .button {
        padding: 10px 20px;
        font-size: 18px;
    }
}