.softSkillsTag {
  color: white;
  font-weight: 400;
  color: #7ED957;
}

.soft-skills-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
}

.soft-skill {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.skill-name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.skill-description {
  font-size: 14px;
  color: #666;
}