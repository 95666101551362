
#container {
  display: flex;
  justify-content: flex-start;
  min-height: 100vh;
  margin: 0;
}

h1 {
  color: #fff;
}


.lista-links {
    list-style: none;
    padding: 0;
  }
  
  /* Estilos para os links */
  .link {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    display: inline-block;
    padding: 10px 20px;
    margin: 10px;
    border: 2px solid #fff; /* Adiciona uma borda sólida */
    border-radius: 10px; /* Adiciona bordas arredondadas */
    transition: border-color 0.3s; /* Adiciona uma transição para a mudança de cor da borda */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra */
    transition: background-color 0.3s, transform 0.2s; /* Transições */
    text-align: center;
    width: 200px;
  }
  
  /* Estilos para animação ao passar o mouse sobre os links */
  .link:hover {
    background-color: chartreuse;
    border-color: chartreuse; /* Altera a cor da borda ao passar o mouse */
    color: #000;
  }